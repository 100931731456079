@import "~@fontsource/montserrat";

.h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 98
};
.h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 61
};
.h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 49
};
.h4 {
    font-family: 'Montserrat', sans-serif;
    font-size: 35
};
.h5 {
    font-family: 'Montserrat', sans-serif;
    font-size: 24
};
.h6 {
    font-family: 'Montserrat', sans-serif;
    font-size: 20
};
.body1{
    font-family: 'Poppins', sans-serif;
    font-size: 16
};
.body2{
    font-family: 'Poppins', sans-serif;
    font-size: 14
};
.subtitle1{
    font-family: 'Montserrat', sans-serif;
    font-size: 16
};
.subtitle2{
    font-family: 'Montserrat', sans-serif;
    font-size: 14
};
.button{
    font-family: 'Poppins', sans-serif;
    font-size: 14
};
.overline{
    font-family: 'Poppins', sans-serif;
    font-size: 10
};
.caption{
    font-family: 'Poppins', sans-serif;
    font-size: 12
};