html {
  scroll-behavior: smooth;
}

#logoImage {
  width: 300px;
}

#codeText {
  padding-bottom: 0px;
  font-size: 40px;
  font-family: "Open Sans", sans-serif !important;
  align-content: bottom;
}

.parallax {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("./img/landing.jpg");
  height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  /* align-items: center; */
  text-align: left;
  color: white;
  display: flex;
}

.parallax-about {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("./img/about.jpg");
  height: 50vh;
  object-fit: contain;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  text-align: left;
  color: white;
  display: flex;
}

.parallax-experience {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("./img/experience_code.jpeg");
  height: 50vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  text-align: left;
  color: white;
  display: flex;
}

.parallax-blog {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("./img/blog.jpeg");
  height: 50vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  text-align: left;
  color: white;
  display: flex;
}

.exp-title:hover {
  text-decoration: underline;
  cursor: pointer;
}

.dark {
  /* --text-color: #E4FDE1; */
  --primary:#88d991;
  --on-primary:#003913;
  --primary-container:#00531f;
  --on-primary-container:#a3f6ab;
  --secondary:#b8ccb5;
  --on-secondary:#243425;
  --secondary-container:#3a4b3a;
  --on-secondary-container:#d4e8d1;
  --tertiary:#a1ced7;
  --on-tertiary:#00363d;
  --tertiary-container:#1f4d54;
  --on-tertiary-container:#bdeaf3;
  --error:#ffb4ab;
  --on-error:#690005;
  --error-container:#93000a;
  --on-error-container:#ffb4ab;
  --background:#1a1c19;
  --on-background:#e2e3dd;
  --surface:#111411;
  --on-surface:#e2e3dd;
  --surface-variant:#424940;
  --on-surface-variant:#c1c9be;
  --outline:#8b9389;
  --outline-variant:#424940;
  --shadow:#000000;
  --scrim:#000000;
  --inverse-surface:#e2e3dd;
  --inverse-on-surface:#2e312d;
  --inverse-primary:#196c31;
  --surface-dim:#111411;
  --surface-bright:#373a36;
  --surface-container-lowest:#0c0f0c;
  --surface-container-low:#1a1c19;
  --surface-container:#1e201d;
  --surface-container-high:#282b27;
  --surface-container-highest:#333532;
}